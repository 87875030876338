body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 16px;

  & > iframe {
    visibility: hidden;
  }

  .app-sidebar-userbox {
    background: #f0f2f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    margin: 0;
    border-radius: 0;
    text-align: center;
    white-space: nowrap;
    min-height: 181px;

    .app-sidebar-userbox-avatar {
      width: 100px;
      height: 100px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }

    .app-sidebar-userbox-name {
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      color: #000000;
      white-space: nowrap;
      margin-bottom: 4px;
    }

    .app-sidebar-userbox-position {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #575c76;
      margin-bottom: 17px;
    }

    .app-sidebar-userbox-description {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height, or 150% */

      color: #000000;
    }

    .app-sidebar-userbox-btn {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .app-sidebar-userbox-btn-profile {
      opacity: 1;
      visibility: visible;
    }
  }

  .app-sidebar-userbox--collapsed {
    padding-left: 20px;
    padding-right: 20px;
    background: transparent;

    .app-sidebar-userbox-avatar {
      width: 34px;
      height: 34px;
      margin-bottom: 0;
    }

    .app-sidebar-userbox-name {
      opacity: 0;
      height: 0;
    }

    .app-sidebar-userbox-btn {
      opacity: 0;
    }

    .app-sidebar-userbox-btn-profile {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.align-items-center {
  align-items: center !important;
}

.justify-content-around {
  justify-content: space-around;
}

.openSidebar {
  width: calc(100% - 330px);
}

.closeSidebar {
  width: calc(100% - 73px);
}

.upload_file {
  display: block;
  cursor: pointer;

  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #9194a9;
    margin: 0 0 10px;
  }

  &__inner {
    width: 250px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f2f6;

    svg {
      cursor: pointer;
    }

    span {
      flex: 1;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #9194a9;
    }
  }
}

.filled_back_purple {
  background: #e4e7ff;
  border-radius: 4px;
  padding: 10px;
}

.modal_r {
  & > div {
    overflow: auto;
  }

  &__inner {
    overflow: auto;
    max-height: calc(100vh - 40px);
  }

  &__inner {
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    min-height: 190px;
    padding: 20px;
    color: #494c62;
    background-color: #F5F5F9;
    border-radius: 4px;

    border: 1px solid #e2e3ed;

    .actions {
      display: flex;
      justify-content: flex-end;

      ._close {
        color: #6191ef;
      }
    }

    .modal-title {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      color: #494c62;
    }

    .MuiFormControl-root,
    .MuiFormControlLabel-root {
      margin-bottom: 20px;
    }

    form {
      margin-top: 20px;

      & > div > button {
        width: auto;
        margin-top: 30px;
      }
    }
  }

  ._white {
    width: 1038px;
    background-color: #fff !important;
    box-shadow: 10px 0 20px rgba(176, 176, 176, 0.1);
    border-radius: 8px;
    color: #404040;

    ._close {
      color: #c7c7c7;
    }
  }

  .call_back_modal {
    padding-bottom: 2%;
  }

  ._form {
    width: fit-content !important;
    margin-top: 1% !important;
    padding: 0 !important;
  }

  & > div > div {
    &:first-child {
      background-color: #07325bcc !important;
    }
  }
}

.select_popover {
  width: max-content;
  &__component {
    min-width: 100px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &_margin {
      margin: 0 15px;
    }

    span {
      display: block;
      background: #e4e7ff;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      color: #72768f;
      padding: 3px 10px;
    }

    svg {
      color: #6191ef;
      font-size: 20px;
    }
  }

  &__content {
    ._item {
      font-weight: 600;
      font-size: 14px;
      line-height: 170%;
      padding: 5px 10px;
      color: #a5a7b5;
      cursor: pointer;

      &:hover {
        background: #e4e7ff;
      }

      &._active {
        color: #494c62;
        background: #e4e7ff;
      }

      &._transferable {
        color: #494c62;
      }
    }
  }
}

.upload_file-wrapper {
  border-bottom: 1px solid #f0f2f6;
  display: flex;
  justify-content: space-between;
  width: 250px;
  padding-bottom: 3px;

  .file-link {
    text-decoration: none;
    color: #6191ef;
    margin-left: 11px;
    flex: 1
  }
}

.filename {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 151px;
  overflow: hidden;
}

.comment_icon {
  display: inline-flex;
  align-items: center;
  background: #9194A9;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  justify-content: center;
  margin-right: 15px;
  color: #fff;
}

.comment-actions {
  color: #9194A9;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  margin: 0 10px 0 0;
  text-decoration: underline;
}